var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-detail df" },
    [
      _c("div", { staticClass: "main-left" }, [
        _c("div", { staticClass: "photo-box bgwh tc mb20" }, [
          _c("img", {
            staticClass: "photos",
            attrs: { src: _vm.imageUrl, alt: "" },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "f18 g6" }, [_vm._v(_vm._s(_vm.info.name))]),
          _vm._v(" "),
          _c("p", { staticClass: "f18 g9" }, [_vm._v(_vm._s(_vm.info.phone))]),
          _vm._v(" "),
          _c("div", { staticClass: "df bte" }, [
            _c("div", { staticClass: "pct50 bre" }, [
              _c("p", { staticClass: "f14 blueFont" }, [_vm._v("师资分类")]),
              _vm._v(" "),
              _c("p", { staticClass: "f16" }, [
                _vm._v(_vm._s(_vm.info.type || "暂无")),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pct50 " }, [
              _c("p", { staticClass: "f14 blueFont" }, [_vm._v("课酬（￥）")]),
              _vm._v(" "),
              _c("p", { staticClass: "f16" }, [_vm._v(_vm._s(_vm.info.money))]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bgwh mb20 p20" }, [
          _c("div", { staticClass: "bbe pb10" }, [_vm._v("操作日志")]),
          _vm._v(" "),
          _c(
            "div",
            { class: [_vm.logShowAll ? "ova" : "ovh"] },
            _vm._l(_vm.computedLog, function (item, index) {
              return _c("div", { key: index, staticClass: "f14 bbe" }, [
                _c("div", { staticClass: "g3 mt5 mt15 mb15" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(item.note) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g9 mb15" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm._f("parseTime")(item.addtime, "{y}-{m}-{d} {h}:{i}")
                      ) +
                      "\n                    "
                  ),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 tc" },
            [
              _vm.log.length > _vm.logLength
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "", round: "" },
                      on: {
                        click: function ($event) {
                          _vm.logShowAll = !_vm.logShowAll
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.logShowAll
                              ? "收起内容  "
                              : "查看全部(" +
                                  (_vm.log.length - _vm.logLength) +
                                  ")"
                          ) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fjsb" }, [
          _c("div", { staticClass: "next bgwh tc" }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.prev_teacher()
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-arrow-left" }),
                _vm._v("上一个师资"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "next bgwh tc" }, [
            _c("div", { staticClass: "next bgwh tc" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.next_teacher()
                    },
                  },
                },
                [
                  _vm._v("下一个师资"),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-right rel" },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                { staticClass: "tadpane", attrs: { label: "基本信息" } },
                [
                  _c("div", { staticClass: "fjsb" }, [
                    _c("ul", { staticClass: "df fww" }, [
                      _c("li", { staticClass: "tabLists" }, [
                        _c("h4", [_vm._v("基本信息")]),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass: "tabstyles",
                            attrs: { border: "1", rules: "all" },
                          },
                          [
                            _c("tr", [
                              _c("th", [_vm._v("师资名称:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.name))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("所属部门:")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.info.structure_name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("状态:")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    Number(_vm.info.status) - 1
                                      ? "终止"
                                      : "进行中"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("主要社会职务:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.post))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("性别:")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(Number(_vm.info.sex) ? "女" : "男")
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("简介:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.remark))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("微信:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.weixin))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("邮箱:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.email))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("生日:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.birthday))]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fields, function (e) {
                              return [
                                e.css === "" || e.css === "基本信息"
                                  ? _c(
                                      "tr",
                                      {
                                        key: e.field,
                                        ref: e.field,
                                        refInFor: true,
                                      },
                                      [
                                        _c("th", [
                                          _vm._v(_vm._s(e.name) + ":"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info[e.field])),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("附件:")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "poi blueFont",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadBtn(_vm.info.file)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.info.file) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("备注:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.note))]),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("h4", [_vm._v("课酬信息")]),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass: "tabstyles",
                            attrs: { border: "1", rules: "all" },
                          },
                          [
                            _c("tr", [
                              _c("th", [_vm._v("开户行:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.bank))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("卡号:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.card_number))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("课酬:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.money))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("收款方式:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.collect_type))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("收款时间:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.collect_time))]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fields, function (e) {
                              return [
                                e.css === "课酬信息"
                                  ? _c(
                                      "tr",
                                      {
                                        key: e.field,
                                        ref: e.field,
                                        refInFor: true,
                                      },
                                      [
                                        _c("th", [
                                          _vm._v(_vm._s(e.name) + ":"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info[e.field])),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "tabLists" }, [
                        _c("h4", [_vm._v("课程信息")]),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass: "tabstyles",
                            attrs: { border: "1", rules: "all" },
                          },
                          [
                            _c("tr", [
                              _c("th", [_vm._v("主要课程:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.course))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("课程大纲:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.outline))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("课程亮点:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.bright_spot))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("排班表:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.schedule))]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fields, function (e) {
                              return [
                                e.css === "课程信息"
                                  ? _c(
                                      "tr",
                                      {
                                        key: e.field,
                                        ref: e.field,
                                        refInFor: true,
                                      },
                                      [
                                        _c("th", [
                                          _vm._v(_vm._s(e.name) + ":"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info[e.field])),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("h4", [_vm._v("师资测评")]),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass: "tabstyles",
                            attrs: { border: "1", rules: "all" },
                          },
                          [
                            _c("tr", [
                              _c("th", [_vm._v("职业素养:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.attainment))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("满意度:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.pleased))]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fields, function (e) {
                              return [
                                e.css === "师资测评"
                                  ? _c(
                                      "tr",
                                      {
                                        key: e.field,
                                        ref: e.field,
                                        refInFor: true,
                                      },
                                      [
                                        _c("th", [
                                          _vm._v(_vm._s(e.name) + ":"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info[e.field])),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("h4", [_vm._v("接待助手")]),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass: "tabstyles",
                            attrs: { border: "1", rules: "all" },
                          },
                          [
                            _c("tr", [
                              _c("th", [_vm._v("常住地:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.home_area))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("饮食:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.food))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("住宿:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.stay))]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("午休:")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(_vm.info.noon_break))]),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fields, function (e) {
                              return [
                                e.css === "接待助手"
                                  ? _c(
                                      "tr",
                                      {
                                        key: e.field,
                                        ref: e.field,
                                        refInFor: true,
                                      },
                                      [
                                        _c("th", [
                                          _vm._v(_vm._s(e.name) + ":"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info[e.field])),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w400 pl30 ble" },
                      [
                        _c(
                          "div",
                          { staticClass: "fjsb fac" },
                          [
                            _c("h4", [_vm._v("跟进记录")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", round: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.FollowupBtn()
                                  },
                                },
                              },
                              [_vm._v(" 新建跟进记录")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.follows_up.length != 0
                          ? _c(
                              "el-timeline",
                              _vm._l(
                                _vm.follows_up,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    { key: index, attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "el-card",
                                        {
                                          attrs: {
                                            "body-style": { padding: "15px" },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _vm._v(
                                                "类型：" + _vm._s(activity.type)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _vm._v(
                                                "主题 ：" +
                                                  _vm._s(activity.subject)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          activity.file_info.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "g6 lh18 blueFont poi",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        附件 ："
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "blueFont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.file_info
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _vm._v(
                                                "下次提醒时间 ：" +
                                                  _vm._s(activity.next_time)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _c(
                              "h3",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("暂无跟进记录")]
                            ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g6 mt30 pl20 f14" }, [
                    _c("span", [_vm._v("创建人:")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mr30" }, [
                      _vm._v(_vm._s(_vm.info.create_name)),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("添加时间:")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mr30" }, [
                      _vm._v(_vm._s(_vm.info.create_time)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "上课记录" } }, [
                _c(
                  "div",
                  { staticClass: "p15" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tooltip-effect": "dark",
                          border: "",
                          "header-cell-style": {
                            background: "#F9F9F9",
                            color: "#222222",
                          },
                          data: _vm.adjustList,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            width: "250",
                            label: "上课编号",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "blueFont poi" }, [
                                    _vm._v(_vm._s(scope.row.class_num)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "所属项目",
                            prop: "xm",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "课程主题",
                            prop: "zhuti",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "班级名称",
                            prop: "class_name",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "上课时间",
                            prop: "class_date",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "上课地点",
                            prop: "class_adress",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "反馈",
                            prop: "fankui",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "备注",
                            prop: "note",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "是否付款",
                            prop: "statusName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "付款记录" } }, [
                _c(
                  "div",
                  { staticClass: "p15" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.generalList,
                          border: "",
                          fit: "",
                          "header-cell-style": {
                            background: "#F9F9F9",
                            color: "#222222",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            width: "250",
                            label: "申请人",
                            prop: "askforman_name",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "blueFont poi",
                                      on: {
                                        click: function ($event) {
                                          _vm.detailVisible = true
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.askforman_name))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "上课编号",
                            prop: "class_num",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "上课时间",
                            prop: "class_date",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "付款时间",
                            prop: "pay_time",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "付款金额",
                            prop: "pay_money",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "付款方式",
                            prop: "pay_type",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.followUpDialog
        ? _c(
            "el-dialog",
            {
              attrs: { title: "跟进", visible: _vm.followUpDialog },
              on: {
                "update:visible": function ($event) {
                  _vm.followUpDialog = $event
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c("el-col", { attrs: { md: 24, lg: 12, span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "followUpForm",
                            staticStyle: { "margin-left": "50px" },
                            attrs: {
                              "label-position": "center",
                              "label-width": "120px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "主题:", prop: "subject" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 3 },
                                  model: {
                                    value: _vm.tempFollowup.subject,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempFollowup, "subject", $$v)
                                    },
                                    expression: "tempFollowup.subject",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "类型:", prop: "type" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.tempFollowup.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempFollowup, "type", $$v)
                                      },
                                      expression: "tempFollowup.type",
                                    },
                                  },
                                  _vm._l(_vm.typeOptionsArr, function (e, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: { label: e, value: e },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "下次提醒日期:" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "选择日期",
                                    format: "yyyy 年 MM 月 dd 日",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.tempFollowup.next_time,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tempFollowup,
                                        "next_time",
                                        $$v
                                      )
                                    },
                                    expression: "tempFollowup.next_time",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "30px" },
                                attrs: { label: "附件:" },
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    attrs: {
                                      "http-request": _vm.uploadSectionFile,
                                      "show-file-list": true,
                                      action: "action",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "w400 pl30 ble" },
                      [
                        _c("div", { staticClass: "fjsb fac" }, [
                          _c("h4", [_vm._v("跟进记录")]),
                        ]),
                        _vm._v(" "),
                        _vm.follows_up.length != 0
                          ? _c(
                              "el-timeline",
                              _vm._l(
                                _vm.follows_up,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    { key: index, attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "el-card",
                                        {
                                          attrs: {
                                            "body-style": { padding: "15px" },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _vm._v(
                                                "类型：" + _vm._s(activity.type)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _vm._v(
                                                "主题 ：" +
                                                  _vm._s(activity.subject)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          activity.file_info.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "g6 lh18 blueFont poi",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    附件 ："
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "blueFont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.file_info
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _vm._v(
                                                "下次提醒时间 ：" +
                                                  _vm._s(activity.next_time)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _c(
                              "h3",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("暂无跟进记录")]
                            ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.followUpDialog = false
                        },
                      },
                    },
                    [_vm._v("\n                取消\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addFollowUp()
                        },
                      },
                    },
                    [_vm._v("\n                确定\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }