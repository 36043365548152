import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetOneTeacherList, TeacherFollow, addTeacherFollow, AttachmentW } from '@/api/ceshi_xiugai/Myteacher';
export default {
  data: function data() {
    return {
      imageUrl: '',
      info: {},
      logShowAll: false,
      log: [],
      logLength: 4,
      fields: undefined,
      follows_up: [],
      followUpDialog: false,
      //新建跟进弹窗
      typeOptionsArr: [],
      //新建类型
      adjustList: [],
      //上课记录
      //新建跟进
      tempFollowup: {
        tid: '',
        subject: '',
        type: '电话',
        next_time: '',
        file_id: '',
        t_name: ''
      },
      generalList: [] //付款
    };
  },
  computed: {
    computedLog: function computedLog() {
      return this.logShowAll ? this.log : this.log.slice(0, this.logLength);
    }
  },
  created: function created() {
    //传来的ID
    this.getoneinfo();
    this.Getfollows_up();
  },
  methods: {
    getoneinfo: function getoneinfo() {
      var _this = this;
      GetOneTeacherList({
        id: this.$route.query.id
      }).then(function (respomse) {
        _this.info = respomse.data;
        _this.tempFollowup.t_name = respomse.data.name;
        _this.imageUrl = process.env.VUE_APP_BASE_API2 + respomse.data.img_info.url;
        if (respomse.data.img_info.url == undefined) {
          _this.imageUrl = 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png';
        } else {
          _this.imageUrl = process.env.VUE_APP_BASE_API2 + respomse.data.img_info.url;
        }
      });
    },
    Getfollows_up: function Getfollows_up() {
      var _this2 = this;
      //跟进列表
      TeacherFollow({
        tid: this.$route.query.id
      }).then(function (res) {
        _this2.follows_up = res.data.follow_list;
        _this2.typeOptionsArr = res.data.teacher_follow_type;
      });
    },
    //新建跟进记录
    FollowupBtn: function FollowupBtn() {
      this.followUpDialog = true;
    },
    addFollowUp: function addFollowUp() {
      var _this3 = this;
      var id = this.$route.query.id;
      this.tempFollowup.tid = id;
      addTeacherFollow(this.tempFollowup).then(function (res) {
        _this3.$notify({
          message: '提交成功',
          type: 'success'
        });
        _this3.followUpDialog = false;
        _this3.$nextTick(function () {
          _this3.Getfollows_up();
          // this.$refs['tempFollowup'].clearValidate();
        });
      });
    },
    //管理编辑
    handleCommand: function handleCommand(row, command) {
      if (+command === 1) {
        this.$router.replace({
          path: 'Myteacher',
          query: {
            id: this.$route.query.id
          }
        });
      }
    },
    //点击上传附件
    uploadSectionFile: function uploadSectionFile(param) {
      var _this4 = this;
      AttachmentW(param).then(function (res) {
        _this4.tempFollowup.file_id = res.data.id;
      });
    }
  }
};