/*
 * @name: 张梦琳
 * @test: test font
 * @Date: 2021-01-25 18:12:41
 * @FilePath: \web_bcrm\src\api\ceshi_xiugai\Myteacher.js
 * @msg:
 * @param:
 * @return:
 */
import request from '@/utils/request';
//获取师资列表（分页）
export function GetTeacherList(data) {
  return request({
    url: '/clientserver.php/Teacher/index',
    method: 'post',
    data: data
  });
}
//新增师资
export function AddTeacherList(data) {
  return request({
    url: '/clientserver.php/Teacher/save',
    method: 'post',
    data: data
  });
}
//修改师资信息
export function UpdataTeacherList(data) {
  return request({
    url: '/clientserver.php/Teacher/update',
    method: 'post',
    data: data
  });
}
//删除一条师资信息
export function DelTeacherList(data) {
  return request({
    url: '/clientserver.php/Teacher/delete',
    method: 'post',
    data: data
  });
}

//获取一条师资信息
export function GetOneTeacherList(data) {
  return request({
    url: '/clientserver.php/Teacher/read',
    method: 'post',
    data: data
  });
}
//师资移交
export function TransferTeacher(data) {
  return request({
    url: '/clientserver.php/Teacher/transferTeacher',
    method: 'post',
    data: data
  });
}

//师资部门zml
export function getGroupList(data) {
  return request({
    url: '/clientserver.php/Common/getGroupList',
    method: 'post',
    data: data
  });
}
// 师资上传图片和附件
// export function Attachment(data) {
//     return request({
//         url: '/clientserver.php/Common/attachment',
//         method: 'post',
//         data: data
//     });
// }

//师资导入zml
export function importTeacherExcel(data) {
  return request({
    url: '/clientserver.php/Teacher/importTeacherExcel',
    method: 'post',
    data: data
  });
}
//下载师资信息导入模板
export function DownloadStandardExcel() {
  return request({
    url: '/clientserver.php/Teacher/downloadStandardExcel',
    method: 'post',
    responseType: 'blob'
  });
}
//导出师资列表
export function exportExcel(data) {
  return request({
    url: '/clientserver.php/Teacher/exportTeacherExcel',
    method: 'post',
    data: data
  });
}

//院校上传图片zzy
export function AttachmentS(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200002');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//院校上传图片zzy
export function AttachmentC(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200006');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//专业上传图片zzy
export function AttachmentZ(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200019');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//院校上传附件zzy
export function AttachmentW(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200003');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//获取跟进列表
export function TeacherFollow(data) {
  return request({
    url: '/clientserver.php/TeacherFollow/index',
    method: 'post',
    data: data
  });
}
export function addTeacherFollow(data) {
  return request({
    url: '/clientserver.php/TeacherFollow/save',
    method: 'post',
    data: data
  });
}